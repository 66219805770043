import axios from 'axios'
import { ThunkAction } from 'redux-thunk'

import { RootState } from '../index'
import { IUpdateQuestionParams } from '.'
import { fetchQnAList, requestQuestionCreateAction, updateQuestionAction } from './actions'
import { requestQnAList, requestQuestionCreate, updateQuestion } from './api'
import { IQnaActions, IQuestionCreateParams } from './types'
import createAsyncThunk from '@/shared/utils/createAsyncThunk'

export const fetchQnAListThunk = createAsyncThunk(fetchQnAList, requestQnAList)

export function requestQuestionCreateThunk(
  data: IQuestionCreateParams,
  sectionId: string,
): ThunkAction<Promise<void>, RootState, null, IQnaActions> {
  return async (dispatch, getState) => {
    const { request, success, failure } = requestQuestionCreateAction
    dispatch(request(undefined))
    try {
      const payload = await requestQuestionCreate(data)
      dispatch(success(payload))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    } finally {
      const pagination = getState().qna.qnaListPagination
      const klassId = getState().router.location.pathname.split('/')[3]
      await dispatch(
        fetchQnAListThunk(false, pagination, `klass_id:${klassId}|section_id:${sectionId}`),
      )
    }
  }
}

export function updateQuestionThunk(
  questionId: string,
  data: IUpdateQuestionParams,
  sectionId: string,
): ThunkAction<Promise<void>, RootState, null, IQnaActions> {
  return async (dispatch, getState) => {
    const { request, success, failure } = updateQuestionAction
    dispatch(request(undefined))
    try {
      const payload = await updateQuestion(questionId, data)
      dispatch(success(payload))
    } catch (e) {
      if (axios.isAxiosError(e)) dispatch(failure(e))
    } finally {
      const pagination = getState().qna.qnaListPagination
      const klassId = getState().router.location.pathname.split('/')[3]
      await dispatch(
        fetchQnAListThunk(false, pagination, `klass_id:${klassId}|section_id:${sectionId}`),
      )
    }
  }
}
