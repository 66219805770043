import useKDTNavList from '@/features/showroom/hooks/useKDTNavList'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useClickOutside } from '@mantine/hooks'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useState, useRef, useEffect, useCallback } from 'react'
import styles from './Header.module.scss'
import TagManager from 'react-gtm-module'

type HoveredSubMenusType = {
  [key: string]: boolean
}

const GNB = () => {
  const pathname = usePathname()
  const { navList } = useKDTNavList()
  const [hoveredSubMenu, setHoveredSubMenu] = useState<HoveredSubMenusType>({})

  const ref = useClickOutside(() => {
    setHoveredSubMenu({})
  })

  useEffect(() => {
    setHoveredSubMenu({})
  }, [pathname])

  const handleMouseEnter = (key: string) => {
    setHoveredSubMenu(prev => ({ ...prev, [key]: true }))
  }

  const handleMouseLeave = (key: string) => {
    setHoveredSubMenu(prev => {
      const newState = { ...prev }
      delete newState[key]
      return newState
    })
  }

  const handleBootcampClick = useCallback((id: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'GNB_bootcamp_btn_click',
        content_ids: id,
        ...(sessionStorage.getItem('utmAndGclid') &&
          JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
      },
    })
  }, [])

  const handleNavClick = useCallback((event: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        ...(sessionStorage.getItem('utmAndGclid') &&
          JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
      },
    })
  }, [])

  return (
    <nav>
      <ul className="group hidden gap-4 lg:flex" ref={ref}>
        {navList.map(({ title, href, event, icon, subMenu }) => {
          const key = `${title}_${href}`
          const isOpen = hoveredSubMenu[key] || false

          const pathName = pathname.split('/')[1]
          const hrefName = href.split('/')[1]

          return (
            <li
              key={key}
              className="group relative list-none"
              onMouseEnter={() => subMenu && handleMouseEnter(key)}
              onMouseLeave={() => subMenu && handleMouseLeave(key)}
            >
              <div className="cursor-pointer list-none group-open:pb-1">
                <div
                  className={`${
                    subMenu && isOpen ? 'bg-[#F5F5F5]' : ''
                  } flex w-full items-center justify-between gap-x-1.5 rounded-lg px-3 py-2.5`}
                >
                  <Link href={href} as={href} onClick={() => event && handleNavClick(event)}>
                    <div
                      className={`flex cursor-pointer text-base font-semibold ${
                        pathName === hrefName ? 'text-yellow-450' : 'text-trueGray-800'
                      } `}
                    >
                      <span className="group-open:text-red">{title}</span>
                    </div>
                  </Link>
                  {subMenu && icon && (
                    <ChevronDownIcon
                      className={`${
                        isOpen ? 'rotate-180 transform' : ''
                      } h-4 w-4 shrink-0 grow-0 text-gray-500`}
                    />
                  )}
                </div>
              </div>
              {subMenu && isOpen && (
                <div className="absolute pt-5" onClick={event => event.stopPropagation()}>
                  <div className="relative z-100 flex w-auto flex-row gap-6 rounded-xl bg-white px-6 pb-8 pt-6 shadow-[6px_6px_60px_-26px_rgba(0,0,0,0.20)]">
                    {subMenu.map(({ title, menuList }, subIdx) => (
                      <div key={`서브메뉴_${subIdx}`}>
                        {title && <p className="mb-4 text-sm font-medium text-gray-400">{title}</p>}
                        <ul className="flex min-w-[152px] flex-col gap-3 bg-white">
                          {menuList.map(({ id, title, href, event, isApply, isExternalLink }) => (
                            <li key={`서브메뉴_${title}`}>
                              <Link
                                href={href}
                                target={isExternalLink ? '_blank' : '_self'}
                                onClick={() => {
                                  id && handleBootcampClick(id)
                                  event && handleNavClick(event)
                                }}
                              >
                                <div
                                  className={`${styles.link_hover} flex flex-row items-center justify-start gap-1.5`}
                                >
                                  <span className="inline-block truncate text-base font-medium text-trueGray-800">
                                    {title}
                                  </span>
                                  {isApply && (
                                    <span className="flex h-5 flex-row items-center justify-center break-words break-keep rounded bg-yellow-450 px-1.5 text-xs font-semibold text-white">
                                      모집중
                                    </span>
                                  )}
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default GNB
