'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useEffect, useState } from 'react'
import AuthProvider from './Auth'
import TagManager from 'react-gtm-module'
import { UtmAndGclidQueryParamsProvider } from './UtmAndGclidQueryParams'
import { GclidQueryParamsProvider } from './GclidQueryParams'
import PlayerProvider from './Player'

const Providers = ({ children }: { children: ReactNode }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TTP7SJTN' })
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UtmAndGclidQueryParamsProvider>
          <GclidQueryParamsProvider>
            <AuthProvider>
              <PlayerProvider>{children}</PlayerProvider>
            </AuthProvider>
          </GclidQueryParamsProvider>
        </UtmAndGclidQueryParamsProvider>
      </QueryClientProvider>
    </>
  )
}

export default Providers
