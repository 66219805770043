import QueryString from 'qs'

import {
  ICourse,
  ICourseResponse,
  ICourseSections,
  ICourseSectionsResponse,
  IFAQPost,
  IKDTKlass,
  IKDTKlassProgressStatResponse,
  IKDTKlassResponse,
  IProgressResource,
  IProgressResponse,
} from './types'
import { ConvertType, convertCaseList } from '@/shared/utils/convertString'
import axiosInstance from '@/shared/utils/axios'

export const requestKDTKlassAttr = async (courseId: string) => {
  const resp = await axiosInstance.get<IKDTKlassResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/course/v1/courses/${courseId}/kdt-attr`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IKDTKlass
}
export const requestKDTBasicKlassList = async (klassId: string) => {
  const resp = await axiosInstance.get<IKDTKlassResponse[]>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/course/v1/courses/${klassId}/kdt-basics`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IKDTKlass[]
}

export const requestCourseList = async (courseType?: string) => {
  const requestQueryString = QueryString.stringify({
    ...(courseType ? { filters: `related_menu:${courseType}` } : {}),
  })
  const resp = await axiosInstance.get<IListDataResp<ICourse>>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/courses?${requestQueryString}`,
  )
  const courseList: IListDataResp<ICourse> = resp.data
  return {
    data: convertCaseList(courseList.data, ConvertType.CAMEL),
    pagination: convertCaseList(courseList.pagination, ConvertType.CAMEL),
  }
}

export const requestCourseDetail = async (courseId: string) => {
  const resp = await axiosInstance.get<ICourseResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/course/v1/courses/${courseId}`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourse
}

export const requestCourseSections = async (courseId: string) => {
  const resp = await axiosInstance.get<ICourseSectionsResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/course/v1/courses/${courseId}/sections`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourseSections
}

export const requestProgressList = async (courseId: string) => {
  const resp = await axiosInstance.get<IProgressResponse[]>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/progress/v1/${courseId}`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestKDTProgressStatList = async (klassId: string) => {
  const resp = await axiosInstance.get<IKDTKlassProgressStatResponse[]>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/progress/v1/kdt/stats/${klassId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestProgressNow = async (courseId: string) => {
  const resp = await axiosInstance.get<IProgressResource>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/progress/v1/${courseId}/now`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestRecommendCourseList = async () => {
  const resp = await axiosInstance.get<IListDataResp<ICourse>>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/cms/recommend`,
  )
  const courseList: IListDataResp<ICourse> = resp.data
  return {
    data: convertCaseList(courseList.data, ConvertType.CAMEL),
    pagination: convertCaseList(courseList.pagination, ConvertType.CAMEL),
  }
}

export const requestCourseFAQList = async (courseId: string) => {
  const resp = await axiosInstance.get<{ data: IFAQPost[] }>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/course/v1/courses/${courseId}/faqs`,
  )
  return resp.data.data
}
