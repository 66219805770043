import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOfAfter)
dayjs.extend(isBetween)

export const checkApplyPeriod = (
  startedAt: string,
  endedAt: string,
): { status: string; dDay?: number | string } => {
  const curDate = dayjs()
  const startDay = dayjs(startedAt)
  const endDay = dayjs(endedAt)
  const daysLeft = endDay.startOf('day').diff(curDate.startOf('day'), 'day')

  if (curDate.isBefore(startDay)) {
    return { status: 'early' }
  }

  if (curDate.isBetween(startDay, endDay)) {
    if (daysLeft <= 7) {
      return { status: 'applyDeadline', dDay: daysLeft }
    }

    return { status: 'apply' }
  }

  return { status: 'applyEnd' }
}
