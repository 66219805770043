import axiosInstance from '@/shared/utils/axios'
import { IQnAListItemResponse, IQuestionCreateParams, IUpdateQuestionParams } from './types'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

export const requestQnAList = async (
  onlyMyQuestion: boolean = false,
  pagination: IPaginationParams,
  filters?: string,
) => {
  const resp = await axiosInstance.get<IListDataResp<IQnAListItemResponse>>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}${onlyMyQuestion ? '/my' : ''}/sqna/v1/questions`,
    {
      params: {
        page: pagination.page ?? 1,
        per_page: pagination.perPage ?? 100,
        sort: 'created_at:desc',
        ...(typeof filters !== 'undefined' ? { filters } : undefined),
      },
    },
  )

  const qnaList: IListDataResp<IQnAListItemResponse> = resp.data
  return {
    data: convertCaseList(qnaList.data, ConvertType.CAMEL),
    pagination: convertCaseList(qnaList.pagination, ConvertType.CAMEL),
  }
}
export const requestQuestionCreate = async (data: IQuestionCreateParams) => {
  const resp = await axiosInstance.post(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/sqna/v1/questions`,
    convertCaseList(data, ConvertType.SNAKE),
  )
  return resp.data
}

export const updateQuestion = async (questionId: string, data: IUpdateQuestionParams) => {
  const resp = await axiosInstance.patch(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/sqna/v1/questions/${questionId}`,
    data,
  )
  return resp.data
}
