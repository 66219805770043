import React, {
  createContext,
  createRef,
  Dispatch,
  FC,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactPlayer from 'react-player'
import { BaseReactPlayerProps } from 'react-player/base'

export interface IPlayerContextState {
  playerRef: RefObject<ReactPlayer>
  player: BaseReactPlayerProps | null
  setPlayer: Dispatch<SetStateAction<BaseReactPlayerProps>>
}

const PlayerContext = createContext<IPlayerContextState>({
  playerRef: createRef<ReactPlayer>(),
  player: {
    playing: true,
    muted: false,
    volume: 0.5,
  },
  setPlayer: () => {},
})

export interface IAuthProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

const PlayerProvider: FC<IAuthProviderProps> = ({ children }) => {
  const playerRef = useRef<ReactPlayer>(null)
  const [playerVideo, setPlayerVideo] = useState<BaseReactPlayerProps>({
    playing: true,
    muted: false,
    volume: 0.5,
  })

  useEffect(() => {
    const handleKeyDown = () => {
      const player = document.getElementById('player')
      player?.focus()
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <PlayerContext.Provider
      value={{ playerRef: playerRef, player: playerVideo, setPlayer: setPlayerVideo }}
    >
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerProvider
export const useKlassReactPlayerPlayer = () => useContext(PlayerContext)
