'use client'

import { useAuth } from '@/shared/providers/Auth'
import BebridgeAIChatbot from '@/shared/utils/bebridgeChatbot'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

const BebrigeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { profile } = useAuth()
  const pathname = usePathname()
  // 비브릿지
  useEffect(() => {
    if (profile) {
      const { id } = profile
      const initializeChatbot = async () => {
        const instance = await BebridgeAIChatbot(String(id))

        if (instance) {
          const relevantPaths = ['/my/courses/detail']
          instance.closeChat()
          if (relevantPaths.some(paths => pathname.includes(paths))) {
            instance.showChatButton()
          } else {
            instance.hideChatButton()
          }
        }
      }
      void initializeChatbot()
    }
  }, [profile, pathname])
  return children
}

export default BebrigeWrapper
